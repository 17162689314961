<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>
          <v-btn color=" lighten-4" class="secondary-button" @click="logout">
            <v-icon class="mr-2">fal fa-sign-out</v-icon> Dezautentificare
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="$store.getters['auth/currentUser'].franchisee_id == null">
        <v-col>
          <clear-cache-button />
        </v-col>
        <v-col>
          <regenerate-customer-feeds-button />
        </v-col>
        <v-col>
          <regenerate-product-attribute-pivot />
        </v-col>
      </v-row>
      <template v-if="$store.getters['auth/currentUser'].franchisee_id">
        <loader :loading="loading" colors="rgba(255, 255, 255, 0.90)" loader-color="#f0483d" size="fa-6x"></loader>
        <v-row class="mt-5">
          <v-col>
            <div v-for="(item, key, index) in verifySettingData" :key="key">
              <div class="mt-3 mb-4 link-section link-section">
                <router-link tag="a" :to="item.url">
                  ({{ index + 1 }}) {{ item.name }}
                </router-link>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import RegenerateProductAttributePivot from '../components/buttons/RegenerateProductAttributePivot'
import ClearCacheButton from '../components/buttons/ClearCacheButton'
import RegenerateCustomerFeedsButton from '../components/buttons/RegenerateCustomerFeedsButton'
import Loader from '@/components/Loader'

export default {
  components: {
    RegenerateCustomerFeedsButton,
    ClearCacheButton,
    RegenerateProductAttributePivot,
    Loader
  },
  data() {
    return {
      loading: false,
      verifySettingData: {}
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.getters['auth/currentUser']
    },
    franchisee_id() {
      return this.$store.getters['auth/currentUser'].franchisee_id
    },
  },
  methods: {
    verifySettings() {
      this.loading = true
      this.$http.get('/settings/verifySettings/' + this.franchisee_id)
        .then(({ data }) => {
          if (data) {
            this.verifySettingData = {
              blnLogoAndFevIcon: {
                id: 1,
                name: 'Setează-ți logo-ul și faviconul',
                isTrue: data.blnLogoAndFevIcon,
                url: `/clients/franchisees/${this.franchisee_id}?type=branding`
              },
              blnCustomiseThemAdmin: {
                id: 2,
                name: 'Personalizați tema pentru admin',
                isTrue: data.blnCustomiseThemAdmin,
                url: `/clients/franchisees/${this.franchisee_id}?type=adminBranding`
              },
              blnCustomiseThemEcomm: {
                id: 3,
                name: 'Personalizați tema pentru Ecommerce',
                isTrue: data.blnCustomiseThemEcomm,
                url: `/clients/franchisees/${this.franchisee_id}?type=branding`
              },
              blnMailServerSettings: {
                id: 4,
                name: 'Setări Mailgun',
                isTrue: data.blnMailServerSettings,
                url: `/clients/franchisees/${this.franchisee_id}?type=mailgun`
              },
              blnInvoiceSerise: {
                id: 5,
                name: 'Personalizați seria de facturare',
                isTrue: data.blnInvoiceSerise,
                url: `/settings/settings?type=invoicingSeries`
              },
              blnHomePageSEOSettings: {
                id: 6,
                name: 'Personalizați-vă conținutul SEO pentru pagina de acasa',
                isTrue: data.blnHomePageSEOSettings,
                url: `/settings/homepage`
              },
              blnContactSEOSettings: {
                id: 7,
                name: 'Personalizați-vă conținutul SEO pentru pagina de contact',
                isTrue: data.blnContactSEOSettings,
                url: `/settings/page-contact`
              },
              blnPageItSEOSettings: {
                id: 8,
                name: 'Personalizați-vă conținutul SEO pentru pagina de IT',
                isTrue: data.blnPageItSEOSettings,
                url: `/settings/page-it`
              },
            }
            data.blnLogoAndFevIcon && this.$delete(this.verifySettingData, 'blnLogoAndFevIcon');
            data.blnCustomiseThemAdmin && this.$delete(this.verifySettingData, 'blnCustomiseThemAdmin');
            data.blnCustomiseThemEcomm && this.$delete(this.verifySettingData, 'blnCustomiseThemEcomm');
            data.blnMailServerSettings && this.$delete(this.verifySettingData, 'blnMailServerSettings');
            data.blnInvoiceSerise && this.$delete(this.verifySettingData, 'blnInvoiceSerise');
            data.blnHomePageSEOSettings && this.$delete(this.verifySettingData, 'blnHomePageSEOSettings');
            data.blnContactSEOSettings && this.$delete(this.verifySettingData, 'blnContactSEOSettings');
            data.blnPageItSEOSettings && this.$delete(this.verifySettingData, 'blnPageItSEOSettings');
          }
          this.loading = false
        })
    },
    logout() {
      this.$store.dispatch('auth/logout')
    }
  },
  created() {
    this.verifySettings()
  }
}
</script>
<style lang="scss">
.link-section {
  a {
    color: red
  }
}
</style>