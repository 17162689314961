<template>
  <div v-if="loading" :style="`background: ${colors}`" class="absolute inset-0 z-10 ">
    <div class="flex flex-col h-full items-center justify-center text-white">
      <i class="fad fa-spinner fa-spin " :class="size" :style="`color: ${this.loaderColor}`"></i>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    loading: {
      required: true
    },
    loaderColor:{
      type: String,
      default: '#fff'
    },
    colors: {
      type: String,
      default: 'rgba(0, 0, 0, 0.5)'
    },
    size:{
      type:String,
      default:'fa-2x'
    }
  }
}
</script>
