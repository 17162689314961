<template>
    <v-btn :disabled="loading" @click="regenerate" :loading="loading">
        Regenereaza Filtrele (attribute)
    </v-btn>
</template>

<script>
export default {
    name: 'RegenerateProductAttributePivot',
    data() {
        return {
            loading: false
        }
    },
    methods: {
        regenerate() {
            this.loading = true
            this.$http.post('helpers/regenerate-product-attributes')
                .then(() => {
                    this.$vs.notify({
                        title: 'Succes!',
                        text: 'Regenerarea filtrelor a intrat in procesare!',
                        color: 'success'
                    })
                })

                .catch(() => {
                    this.$vs.notify({
                        title: 'Eroare',
                        text: 'Eroare la regenerare. Va rugam reincercati',
                        color: 'danger'
                    })
                })
                .finally(() => {

                    this.loading = false
                })
        }
    }

}
</script>

<style scoped>

</style>
